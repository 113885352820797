










































import { Component, Emit, Prop, Ref } from "vue-property-decorator";
import { FormField } from "@/components/form-components/types";
import FormTextAreaField from "@/components/form-components/FormTextAreaField.vue";
import FormInputField from "@/components/form-components/FormInputField.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Component({
  components: { FormTextAreaField, FormInputField },
})
@Translatable("modals.partials")
export default class ReasonModalContent extends TranslatableComponent {
  @Ref() readonly reasonInput!: FormField;
  @Prop({ default: true }) private reasonMandatory!: boolean;
  @Prop({ default: "reason" }) private label!: string;

  private reason: string = "";

  @Emit("action_cancel")
  private cancel() {
    this.reason = "";
  }

  @Emit("action_submit")
  private submit() {
    return new Promise((resolve) => {
      if (!this.reasonInput.validate()) {
        this.$snotify.error("Niet alle velden zijn correct ingevuld.");
        return;
      }
      resolve(this.reason);
    });
  }
}
