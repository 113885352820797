var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h2',{staticClass:"modal-title basic-modal-title"},[_vm._v(" "+_vm._s(_vm.translated_component_value("title"))+" ")]),_c('div',{staticClass:"modal-text basic-modal-text"},[_vm._v(" "+_vm._s(_vm.translated_component_value("content"))+" ")]),(
      _vm.document.state === 'ADOPTION_ACCEPTED' ||
      _vm.document.state === 'ADOPTION_REJECTED' ||
      _vm.document.state === 'SIGNABLE_DOCUMENTS_CREATED' ||
      _vm.document.state === 'SENT' ||
      _vm.document.state === 'OWNER_SIGNED'
    )?_c('adoption-progress-steps',{attrs:{"document":_vm.document}}):_vm._e(),(
      _vm.document.state === 'SBR_SENT' ||
      _vm.document.state === 'SBR_SEND_ERROR' ||
      _vm.document.state === 'SBR_COMPLETE' ||
      _vm.document.state === 'ARCHIVING' ||
      _vm.document.state === 'PROCESSED'
    )?_c('div',{staticClass:"modal-text"},[_vm._v(" "+_vm._s(_vm.translated_component_value("process-complete"))+" ")]):_vm._e(),_c('div',{staticClass:"modal-buttons basic-modal-buttons"},[_c('button',{staticClass:"button flexed button--secondary",attrs:{"id":"button-cancel","type":"button"},on:{"click":_vm.canceled}},[_vm._v(" "+_vm._s(_vm.translated_component_value("cancel"))+" ")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }