


























import { Component, Emit, Prop, Ref, Vue } from "vue-property-decorator";
import { FormField } from "@/components/form-components/types";
import ReasonModalContent from "@/components/modals/partials/ReasonModalContent.vue";

@Component({
  components: { ReasonModalContent },
})
export default class ReasonModal extends Vue {
  @Ref() readonly reasonInput!: FormField;
  @Prop() private title!: string;
  @Prop() private content!: string;
  @Prop({ default: "Reden" }) private label!: string;
  @Prop() private componentName!: string;
  @Prop({ default: true }) private reasonMandatory!: boolean;

  @Emit("cancel")
  private cancel() {
    this.$modal.hide(this.componentName);
  }

  @Emit("submit")
  private submit(reason: string) {
    this.$modal.hide(this.componentName);
    return reason;
  }
}
