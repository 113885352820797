




















import { Component, Emit, Prop, Ref } from "vue-property-decorator";
import { Document } from "@/models/document";
import AdoptDocument from "@/components/modals/partials/AdoptDocument.vue";
import SignDocument from "@/components/modals/partials/SignDocument.vue";
import AdoptionProgress from "@/components/modals/partials/AdoptionProgress.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Component({
  components: { AdoptDocument, SignDocument, AdoptionProgress },
  props: {
    document: Document,
    componentName: String,
  },
})
@Translatable("modals")
export default class ApproveDocumentModal extends TranslatableComponent {
  @Prop() private document!: Document;
  @Prop() private componentName!: string;
  @Ref() private dialogReference!: any;

  get dialogContent():
    | "adoption-progress"
    | "sign-document"
    | "adopt-document" {
    return this.document.state === "ADOPTION_REQUIRED"
      ? "adopt-document"
      : this.document.state === "OWNER_SIGNED"
      ? "sign-document"
      : "adoption-progress";
  }

  @Emit()
  private cancel() {
    return;
  }

  @Emit()
  private stepFinished(
    step: "sign-document" | "adopt-document"
  ): "sign-document" | "adopt-document" {
    return step;
  }
}
