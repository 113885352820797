































import Component from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Document } from "@/models/document";
import { Emit, Prop } from "vue-property-decorator";
import DocumentLayout from "@/components/documents/DocumentLayout.vue";
import DocumentDetails from "@/components/documents/DocumentDetails.vue";
import DocumentAttachments from "@/components/documents/DocumentAttachments.vue";
import ReasonModal from "@/components/modals/ReasonModal.vue";
import { tasksApi } from "@/api/portal/tasks";
import { ActionType, DocumentAction } from "@/views/documents/DocumentActions";
import { insertIf } from "@/lib/helpers/conditional";
import { TaskCategory } from "@/models/task";
import AuditlogComponent from "@/components/documents/AuditlogComponent.vue";
import UserAction from "@/components/documents/user_actions/UserAction.vue";
import RejectDocumentModal from "@/components/modals/RejectDocumentModal.vue";
import { TaskRedirect } from "@/views/documents/task_redirects";

@Component({
  components: {
    RejectDocumentModal,
    ReasonModal,
    DocumentLayout,
    DocumentDetails,
    DocumentAttachments,
    AuditlogComponent,
    UserAction,
  },
})
@Translatable("documents.states")
export default class DeclinedState extends TranslatableComponent {
  @Prop()
  private document!: Document;

  @Prop()
  private taskCategory!: TaskCategory;

  get userActions(): {
    value: DocumentAction;
    action: () => any;
    actionType: ActionType;
  }[] {
    if (this.document === null) {
      return [];
    }
    return [
      ...insertIf(this.$ability.can("revoke", this.document), {
        value: "revoke_document",
        action: () => this.$modal.show("rejectModalEmployee"),
        actionType: "secondary",
      }),
      ...insertIf(this.$ability.can("resubmit", this.document), {
        value: "resubmit",
        action: () => this.$modal.show("resubmitModal"),
        actionType: "primary",
      }),
    ] as {
      value: DocumentAction;
      action: () => any;
      actionType: ActionType;
    }[];
  }

  private reSubmitDocument(reason: string) {
    tasksApi
      .updateStatus(this.document.id, "INITIAL", reason)
      .then((data) => {
        this.$snotify.success(
          this.translated_component_value("notifications.resubmit.success")
        );
        this.return("documents");
      })
      .catch((data) => {
        this.$snotify.error(
          this.translated_component_value("notifications.resubmit.error")
        );
      });
  }

  @Emit("return")
  private return(redirectPath: TaskRedirect) {}
}
