



























































































































































import { Component, Prop } from "vue-property-decorator";
import { Document } from "@/models/document";
import { filter } from "lodash";
import { mixins } from "vue-class-component";
import { formatIsoDate } from "@/filters";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Participant } from "@/models/participant";
import { DocumentType } from "@/enums/DocumentType";

import { ParticipantRole } from "@/enums/ParticipantRole";
import { ParticipantType } from "@/enums/ParticipantType";
import { CustomerSigningAction } from "@/enums/CustomerSigningAction";
import { NexusRecipient, nexusRecipients } from "@/enums/NexusRecipient";

@Component({
  methods: { formatIsoDate },
})
@Translatable()
export default class DocumentDetails extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  Document = Document;

  @Prop()
  private document!: Document;

  private get customerSigners(): Participant[] {
    const customerSigners = this.document.participants.filter(
      (participant) =>
        participant.role === ParticipantRole.SIGNER &&
        participant.participantType === ParticipantType.CUSTOMER
    );

    return customerSigners.sort(
      (a: Participant, b: Participant) =>
        Number(b.hasSigned) - Number(a.hasSigned)
    );
  }

  private get canBeRepeatable(): boolean {
    return this.document.documentType === DocumentType.OMZETBELASTING;
  }

  private get employeeSigners(): Participant[] {
    return filter(this.document.participants, {
      role: ParticipantRole.SIGNER,
      participantType: ParticipantType.EMPLOYEE,
    });
  }

  private get editors(): Participant[] {
    return filter(this.document.participants, {
      role: ParticipantRole.EDITOR,
    });
  }

  private getNexusRecipientsNameByValue(value: NexusRecipient): string {
    return nexusRecipients.some((item) => item === value)
      ? this.model_human_attribute_value("NexusRecipient", value).toString()
      : "-";
  }

  private get CustomerSigningAction(): typeof CustomerSigningAction {
    return CustomerSigningAction;
  }
}
