



































import { Component, Prop, Vue } from "vue-property-decorator";
import { FormField } from "@/components/form-components/types";

@Component({
  components: {},
  inheritAttrs: false,
})
export default class FormTextAreaField extends Vue implements FormField {
  /**
   * Things like inputmode, pattern, placeholder, minlength, maxlength, required, etc.
   * Are bound 1-on-1 through v-bind="$attrs"
   */

  @Prop({ default: "" }) public label!: string;

  @Prop({ default: false, type: Boolean }) required!: boolean;

  public isValidatedOnce = false;
  public autoFilled = true;
  protected focused: boolean = false;

  get type() {
    return this.$attrs.type || "text";
  }

  get filled() {
    const value = (this.$refs.input as HTMLFormElement).value || "";
    return this.focused || value.length > 0;
  }

  public validate(): boolean {
    this.isValidatedOnce = true;
    return this.isValid();
  }

  protected onInput() {
    this.autoFilled = false;
    this.isValidatedOnce = false;
  }

  protected isValid(): boolean {
    return this.isValidatedOnce ? this.checkValidity() : true;
  }

  protected getValidity() {
    const input = this.getInputField();
    return input ? input.validity : {};
  }

  protected updateValue(value: any) {
    this.$emit("input", value);
  }

  mounted() {
    // Workaround to get the label out of the field it's autofilled.
    // We're assuming it's autofilled until we know that it isn't.
    document.addEventListener("DOMContentLoaded", (evt) => {
      setTimeout(() => {
        this.autoFilled = this.isAutoFilled();
      }, 500);
    });
    setTimeout(() => {
      this.autoFilled = this.isAutoFilled();
    }, 500);
  }

  private isAutoFilled() {
    return (
      this.$el && this.$el.querySelectorAll("input:-webkit-autofill").length > 0
    );
  }

  private getInputField(): HTMLInputElement | null {
    return this.$refs.input ? (this.$refs.input as HTMLInputElement) : null;
  }

  private checkValidity(): boolean {
    const input = this.getInputField();
    return input ? input.checkValidity() : false;
  }
}
