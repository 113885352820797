var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('page',{attrs:{"page":_vm.$route.name},scopedSlots:_vm._u([{key:"the-header",fn:function(){return [_c('the-header',{attrs:{"page":_vm.$route.name,"image":"task.jpg"},scopedSlots:_vm._u([{key:"header",fn:function(){return [_vm._v(_vm._s(_vm.translated_view_value("header.title")))]},proxy:true},(_vm.document)?{key:"default",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.document.title))]),_c('p',[_vm._v(" "+_vm._s(((_vm.translated_view_value( "header.status" )) + " " + (_vm.model_human_attribute_value( "DocumentStatus", _vm.document.state ))))+" "),_c('br'),_vm._v(" "+_vm._s(_vm.translated_view_value("header.endDate"))+"   "+_vm._s(_vm.formatIsoDate(_vm.document.endDate))+" ")])]},proxy:true}:null],null,true)})]},proxy:true},{key:"default",fn:function(){return [_c('loader',{attrs:{"data-loading":_vm.isLoading}},[_c('router-link',{attrs:{"to":{
          name: 'documents',
          params: { scope: 'SELF' },
          query: {
            category: _vm.taskCategory,
            selectedAccountId: _vm.selectedAccountId,
          },
        },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('span',{staticClass:"link-with-icon back",attrs:{"role":"link"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.translated_view_value(("back_to." + _vm.taskCategory))))])]}}])}),(!_vm.hasDocument())?[_c('div',{staticClass:"detail-page"},[_c('h1',[_vm._v(_vm._s(_vm.translated_view_value("not_found.title")))]),_c('p',[_vm._v(_vm._s(_vm.translated_view_value("not_found.message")))]),_c('br'),_c('router-link',{attrs:{"to":{
              name: 'documents',
              query: {
                category: _vm.taskCategory,
                selectedAccountId: _vm.selectedAccountId,
              },
            },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var navigate = ref.navigate;
return [_c('span',{staticClass:"button button--primary to-documents",attrs:{"role":"link","tag":"button"},on:{"click":navigate,"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.translated_view_value("back_button"))+" ")])]}}],null,false,864261952)})],1)]:[_c(_vm.toComponent(),{tag:"component",attrs:{"document":_vm.document,"message-bus":_vm.messageBus},on:{"return":_vm.returnToDocumentList},scopedSlots:_vm._u([{key:"second-column",fn:function(){return [(_vm.representativeContactDetails)?_c('contact-card-minimalistic',{attrs:{"representative-contact-details":_vm.representativeContactDetails}}):_vm._e()]},proxy:true}])}),_c('adoption',{attrs:{"document":_vm.document,"message-bus":_vm.messageBus}})]],2)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }