import axios, { AxiosInstance, AxiosResponse } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";

export interface RedirectUrl {
  url: string;
}

interface SbrVerwerkerEndpoint {
  create: (
    documentId: string,
    redirectUrl?: string
  ) => Promise<AxiosResponse<RedirectUrl>>;
  finish: (documentId: string) => Promise<AxiosResponse>;
}

export interface SbrVerwerkerApi {
  sbrverwerker: SbrVerwerkerEndpoint;
}

export const sbrVerwerkerApi = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_SBR_VERWERKER_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

const api: SbrVerwerkerApi = {
  sbrverwerker: {
    create(documentId: string, redirectUrl?: string) {
      return typeof redirectUrl !== "undefined"
        ? sbrVerwerkerApi().put(`/api/sbrverwerker/${documentId}`, null, {
            params: { redirectUrl },
          })
        : sbrVerwerkerApi().put(`/api/sbrverwerker/${documentId}`);
    },
    finish(documentId: string) {
      return sbrVerwerkerApi().post(
        `/api/sbrverwerker/${documentId}/finish`,
        null
      );
    },
  },
};

export default api;
