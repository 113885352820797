



























import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component, Prop } from "vue-property-decorator";

import AttachmentRow from "@/components/attachments/AttachmentRow.vue";
import { Attachment } from "@/components/attachments/AttachmentListTypes";

@Component({
  components: { AttachmentRow },
})
@Translatable("attachments")
export default class AttachmentList extends TranslatableComponent {
  @Prop()
  private attachments!: Attachment[];
}
