


















import { Component, Prop, Vue } from "vue-property-decorator";
import api from "@/api/audit";
import { AuditLog } from "@/models/auditlog";
import AuditLogTable from "@/components/documents/AuditLogTable.vue";
import Loader from "@/components/Loader.vue";

@Component({
  components: { AuditLogTable, Loader },
})
export default class AuditLogModal extends Vue {
  @Prop() private documentId!: string;

  private data: AuditLog | null = null;
  private isLoading: boolean = false;

  get hasData(): boolean {
    return this.data !== null;
  }

  mounted() {
    this.isLoading = true;
    api.documents
      .index(this.documentId)
      .then((data) => {
        this.data = data.data;
      })
      .catch(() => {
        this.data = null;
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
