








import { Component, Prop } from "vue-property-decorator";
import AuditLogModal from "@/components/documents/AuditLogModal.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";

@Component({
  components: { AuditLogModal },
})
@Translatable("documents")
export default class AuditlogComponent extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop()
  private documentId!: string;

  private showAuditLogModal(documentId: string) {
    this.$modal.show(
      AuditLogModal,
      {
        documentId,
      },
      {
        minHeight: 135,
        adaptive: true,
        height: "auto",
        scrollable: true,
      }
    );
  }
}
