















import { Component, Emit, Prop } from "vue-property-decorator";
import { tasksApi } from "@/api/portal/tasks";
import ReasonModalContent from "@/components/modals/partials/ReasonModalContent.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { TaskCategory } from "@/models/task";

@Component({
  components: { ReasonModalContent },
})
@Translatable("modals")
export default class SendEmployeeMessageModal extends TranslatableComponent {
  @Prop() private documentId!: string;
  @Prop() private category!: TaskCategory;

  @Emit("close")
  private cancel(parameters: boolean) {
    return parameters;
  }

  private submit(reason: string): void {
    tasksApi
      .updateStatus(this.documentId, "ARCHIVING", reason || "")
      .then(() => {
        this.$snotify.success("Document is succesvol verzonden.");
        this.cancel(true);
        this.$router.push({
          name: "documents",
          query: { category: this.category },
        });
      })
      .catch((data) => {
        this.$snotify.error(
          "Er is iets mis gegaan bij het versturen van het document."
        );
      });
  }
}
