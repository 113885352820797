

























import Component from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Document } from "@/models/document";
import { Prop } from "vue-property-decorator";
import DocumentLayout from "@/components/documents/DocumentLayout.vue";
import DocumentDetails from "@/components/documents/DocumentDetails.vue";
import DocumentAttachments from "@/components/documents/DocumentAttachments.vue";
import AuditlogComponent from "@/components/documents/AuditlogComponent.vue";
import SendEmployeeMessageModal from "@/components/modals/SendEmployeeMessageModal.vue";
import { TaskCategory } from "@/models/task";
import { ActionType, DocumentAction } from "@/views/documents/DocumentActions";
import { insertIf } from "@/lib/helpers/conditional";
import { isEmpty } from "lodash";
import UserAction from "@/components/documents/user_actions/UserAction.vue";
import RejectDocumentModal from "@/components/modals/RejectDocumentModal.vue";

@Component({
  methods: { isEmpty },
  components: {
    RejectDocumentModal,
    AuditlogComponent,
    DocumentLayout,
    DocumentDetails,
    DocumentAttachments,
    UserAction,
  },
})
@Translatable("documents.states")
export default class ReadyForSendingState extends TranslatableComponent {
  @Prop()
  private document!: Document;

  @Prop()
  private taskCategory!: TaskCategory;

  get userActions(): {
    value: DocumentAction;
    action: () => any;
    actionType: ActionType;
  }[] {
    if (this.document === null) {
      return [];
    }
    return [
      ...insertIf(this.$ability.can("send", this.document), {
        value: "send_document",
        action: () => this.employeeSending(this.document.id),
        actionType: "primary",
      }),
      ...insertIf(this.$ability.can("revoke", this.document), {
        value: "revoke_document",
        action: () => this.$modal.show("revokeModalEmployee"),
        actionType: "secondary",
      }),
    ] as {
      value: DocumentAction;
      action: () => any;
      actionType: ActionType;
    }[];
  }

  private employeeSending(documentId: string) {
    this.$modal.show(
      SendEmployeeMessageModal,
      { documentId, i18n: this.$i18n, category: this.taskCategory },
      { height: "auto", maxWidth: 600, adaptive: true }
    );
  }
}
