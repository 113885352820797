


























import { Component, Prop } from "vue-property-decorator";
import TranslatableComponent, {
  Translatable,
} from "../../lib/mixins/translateable";
import {
  Attachment,
  Preview,
} from "@/components/attachments/AttachmentListTypes";
import {
  PreviewContentType,
  SupportedContentType,
} from "@/components/attachments/AttachmentTypes";
import { AxiosResponse } from "axios";

@Component({
  components: {
    "viewer-pdf": () => import("@/components/attachments/ViewerPdf.vue"),
    "viewer-html": () => import("@/components/attachments/ViewerHtml.vue"),
  },
})
@Translatable("attachments")
export default class AttachmentRow extends TranslatableComponent {
  @Prop() private attachment!: Attachment;

  private previewComponent: Preview | null = null;

  get canPreview(): boolean {
    return (
      this.previewableContentType(
        this.attachment.contentType as SupportedContentType
      ) !== undefined
    );
  }

  private resolvePreviewComponent(
    contentType: PreviewContentType
  ): string | undefined {
    const previewMapping: Record<PreviewContentType, string> = {
      "application/pdf": "viewer-pdf",
      "text/html": "viewer-html",
    };

    return previewMapping[contentType];
  }

  private previewableContentType(
    contentType: SupportedContentType
  ): PreviewContentType | undefined {
    const previewMapping: Record<SupportedContentType, PreviewContentType> = {
      "application/pdf": "application/pdf",
      "application/xml": "text/html",
      "application/xbrl": "text/html",
      "application/xbrl+xml": "text/html",
      "text/html": "text/html",
    };

    return previewMapping[contentType];
  }

  private downloadAttachment(attachment: Attachment) {
    attachment
      .download()
      .then((response: AxiosResponse<BlobPart>) => {
        let blob = new Blob([response.data], { type: attachment.contentType });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = attachment.name;
        link.click();
      })
      .catch(() => {
        this.$snotify.error(
          `${this.translated_component_value("can_not_be_downloaded")}`
        );
      });
  }

  private previewAttachment(attachment: Attachment) {
    const contentType = this.previewableContentType(
      attachment.contentType as SupportedContentType
    );
    if (contentType === undefined) {
      this.$snotify.error(
        `${this.translated_component_value("cant_be_previewed")}`
      );
      return;
    }
    attachment
      .preview(contentType)
      .then((response) => {
        this.previewComponent = null;
        return response;
      })
      .then((response) => {
        return {
          component: this.resolvePreviewComponent(contentType),
          data: response.data,
          attachmentName: attachment.name,
        } as Preview;
      })
      .then((component) => {
        if (!component.component) {
          throw new Error(
            `${this.translated_component_value("component_doesnt_exist")}`
          );
        }
        return component;
      })
      .then((component) => {
        this.previewComponent = component;
      })
      .catch((error) => {
        this.$snotify.error(error);
        this.previewComponent = null;
      });
  }
}
