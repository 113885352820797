var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.document.archived)?_c('div',[(_vm.isCustomer)?_c('p',{staticClass:"archived-text"},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.translated_view_value('customer_archived_text'))}}),_c('router-link',{staticClass:"link-with-icon",attrs:{"to":{
          name: 'dossier',
          query: {
            selectedAccountIds: [_vm.document.account.id],
            searchFilter: _vm.document.title,
          },
        },"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var navigate = ref.navigate;
return [_c('a',{attrs:{"role":"link","tabindex":"0"},on:{"click":navigate,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return navigate.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.translated_view_value("to_dossier"))+" ")])]}}],null,false,3933983143)})],1):_vm._e(),(_vm.isEmployee)?_c('p',{staticClass:"archived-text"},[_vm._v(" "+_vm._s(_vm.translated_view_value("employee_archived_text"))+" ")]):_vm._e()]):_c('attachment-list',{attrs:{"attachments":_vm.attachments}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }