export const nexusRecipients = [
  "Testbank",
  "Credit_Agricole",
  "ABN_AMRO",
  "October",
  "Rabobank",
  "ING_Bank",
  "Bouwend_Nederland",
  "Funding_Circle",
  "Pentrax",
  "Volksbank",
] as const;

export type NexusRecipient = typeof nexusRecipients[number];
