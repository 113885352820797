


















































import { Component, Prop } from "vue-property-decorator";
import { AuditLog } from "@/models/auditlog";
import { formatIsoDateTime } from "@/filters";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";

@Component({ methods: { formatIsoDateTime } })
@Translatable()
export default class AuditLogTable extends mixins<TranslatableComponent>(
  TranslatableComponent
) {
  @Prop() private auditLogs!: AuditLog;
}
