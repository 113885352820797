












import Component, { mixins } from "vue-class-component";
import TranslatableComponent from "@/lib/mixins/translateable";
import { TranslatedUserActions } from "@/components/documents/user_actions/useractions";
import { Prop } from "vue-property-decorator";

@Component({})
export default class CustomerUserActions extends mixins(TranslatableComponent) {
  @Prop()
  private actions!: TranslatedUserActions[];
}
