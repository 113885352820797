




































import Component, { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Document } from "@/models/document";
import { Emit, Prop } from "vue-property-decorator";
import DocumentLayout from "@/components/documents/DocumentLayout.vue";
import DocumentDetails from "@/components/documents/DocumentDetails.vue";
import DocumentAttachments from "@/components/documents/DocumentAttachments.vue";
import AuditlogComponent from "@/components/documents/AuditlogComponent.vue";
import { isEmpty } from "lodash";
import { TaskCategory } from "@/models/task";
import { ActionType, DocumentAction } from "@/views/documents/DocumentActions";
import { insertIf } from "@/lib/helpers/conditional";
import ReasonModal from "@/components/modals/ReasonModal.vue";
import { tasksApi } from "@/api/portal/tasks";
import ValidSignModal from "@/components/modals/ValidSignModal.vue";
import Vue from "vue";
import RejectDocumentModal from "@/components/modals/RejectDocumentModal.vue";
import UserAction from "@/components/documents/user_actions/UserAction.vue";
import { TaskRedirect } from "@/views/documents/task_redirects";

@Component({
  methods: { isEmpty },
  components: {
    UserAction,
    ValidSignModal,
    ReasonModal,
    AuditlogComponent,
    DocumentLayout,
    DocumentDetails,
    DocumentAttachments,
    RejectDocumentModal,
  },
})
@Translatable("documents.states")
export default class OwnerSignedState extends mixins(TranslatableComponent) {
  @Prop()
  private document!: Document;

  @Prop()
  private taskCategory!: TaskCategory;

  @Prop()
  private messageBus!: Vue;

  get userActions(): {
    value: DocumentAction;
    action: () => any;
    actionType: ActionType;
  }[] {
    if (this.document === null) {
      return [];
    }
    return [
      ...insertIf(this.$ability.can("sign", this.document, "customer"), {
        value: "sign_document",
        action: this.openSigningModal,
        actionType: "primary",
      }),
      ...insertIf(this.$ability.can("reject", this.document), {
        value: "reject_document",
        action: () => this.$modal.show("rejectModalClient"),
        actionType: "secondary",
      }),
      ...insertIf(this.$ability.can("revoke", this.document), {
        value: "revoke_document",
        action: () => this.$modal.show("revokeModalEmployee"),
        actionType: "secondary",
      }),
    ] as {
      value: DocumentAction;
      action: () => any;
      actionType: ActionType;
    }[];
  }

  mounted() {
    this.messageBus.$on("start-signing", this.openSigningModal);
  }

  beforeDestroy() {
    this.messageBus.$off("start-signing", this.openSigningModal);
  }

  private rejectDocument(reason: string) {
    tasksApi
      .updateStatus(this.document.id, "DECLINED", reason)
      .then((data) => {
        this.$snotify.success(
          this.translated_component_value("notifications.reject.success")
        );
        this.return("documents");
      })
      .catch((data) => {
        this.$snotify.error(
          this.translated_component_value("notifications.reject.error")
        );
      });
  }

  private openSigningModal() {
    tasksApi
      .signingLink(this.document.id)
      .then((data) => data.data)
      .then((data) => {
        this.messageBus.$emit("show-signing-modal", {
          url: data.signingLink,
          packageId: this.document.signingInfo?.packageId,
        });
      })
      .catch((error) => {
        this.$snotify.error(
          this.translated_component_value("notifications.signing_link.error")
        );
      });
  }

  private finishSigning(message: string) {
    this.$snotify.success(
      this.translated_component_value("notifications.signing.success")
    );
    this.messageBus.$emit("hide-signing-modal");
    this.return("signing");
  }

  private failedSigning(message: string) {
    this.$snotify.error(
      this.translated_component_value("notifications.signing.failed")
    );
  }

  @Emit("return")
  private return(redirectPath: TaskRedirect) {}
}
