











import { Component, Prop, Vue } from "vue-property-decorator";
import TranslatableComponent from "@/lib/mixins/translateable";
import { mixins } from "vue-class-component";
import { Document } from "@/models/document";
import ApproveDocumentModal from "@/components/modals/ApproveDocumentModal.vue";
import ValidSignModal from "@/components/modals/ValidSignModal.vue";

@Component({
  components: { ValidSignModal, ApproveDocumentModal },
})
export default class Adoption extends mixins(TranslatableComponent) {
  @Prop()
  readonly messageBus!: Vue;

  @Prop()
  private document!: Document;

  mounted() {
    this.messageBus.$on("show-adoption-modal", this.showAdoptionModal);
    this.messageBus.$on("hide-adoption-modal", this.closeAdoptModal);
  }

  beforeDestroy() {
    this.messageBus.$off("show-adoption-modal", this.showAdoptionModal);
    this.messageBus.$off("hide-adoption-modal", this.closeAdoptModal);
  }

  private showAdoptionModal() {
    this.$modal.show("integratedAdoptionClient");
  }

  private closeAdoptModal() {
    this.$modal.hide("integratedAdoptionClient");
  }

  private adoptStepFinished(step: "sign-document" | "adopt-document") {
    if (step === "sign-document") {
      this.closeAdoptModal();
      this.messageBus.$emit("start-signing");
    }
  }
}
