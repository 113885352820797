



import Component, { mixins } from "vue-class-component";
import TranslatableComponent from "@/lib/mixins/translateable";
import { Role } from "@/models/role";
import { namespace } from "vuex-class";
import { Prop } from "vue-property-decorator";
import {
  TranslatedUserActions,
  UserActionComponents,
  UserActions,
} from "@/components/documents/user_actions/useractions";
import { chain } from "lodash";
import EmployeeUserActions from "@/components/documents/user_actions/EmployeeUserActions.vue";
import CustomerUserActions from "@/components/documents/user_actions/CustomerUserActions.vue";

const user = namespace("user");

@Component({
  components: {
    "employee-actions": EmployeeUserActions,
    "customer-actions": CustomerUserActions,
  },
})
export default class UserAction extends mixins(TranslatableComponent) {
  @user.State("role")
  private role!: Role;

  @Prop()
  private userActions!: UserActions[];

  get actions(): UserActionComponents {
    const components: Record<Role, UserActionComponents> = {
      employee: "employee-actions",
      customer: "customer-actions",
    };
    return components[this.role];
  }

  private sortOrder() {
    return this.role === "employee" ? "asc" : "desc";
  }

  get translatedActions(): TranslatedUserActions[] {
    return chain(this.userActions)
      .map((item) => {
        return {
          value: this.model_human_attribute_value("DocumentAction", item.value),
          action: item.action,
          actionType: item.actionType,
        };
      })
      .orderBy("actionType", this.sortOrder())
      .value();
  }
}
