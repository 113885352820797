
















import Component from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Document } from "@/models/document";
import { Prop } from "vue-property-decorator";
import DocumentLayout from "@/components/documents/DocumentLayout.vue";
import DocumentDetails from "@/components/documents/DocumentDetails.vue";
import DocumentAttachments from "@/components/documents/DocumentAttachments.vue";
import AuditlogComponent from "@/components/documents/AuditlogComponent.vue";

@Component({
  components: {
    AuditlogComponent,
    DocumentLayout,
    DocumentDetails,
    DocumentAttachments,
  },
})
@Translatable("documents.states")
export default class NoOperations extends TranslatableComponent {
  @Prop()
  private document!: Document;
}
