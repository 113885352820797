import axios, { AxiosInstance } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";

const portalApi = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

export const attachmentsApi = {
  get(taskId: string, attachmentId: string, mediaType: string) {
    return portalApi().get(
      `/api/documents/${taskId}/attachments/${attachmentId}`,
      { responseType: "arraybuffer", headers: { Accept: mediaType } }
    );
  },
  preview(taskId: string, attachmentId: string, mediaType: string) {
    return portalApi().get(
      `/api/documents/${taskId}/attachments/${attachmentId}/view`,
      { responseType: "arraybuffer", headers: { Accept: mediaType } }
    );
  },
};
