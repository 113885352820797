import axios, { AxiosInstance, AxiosResponse } from "axios";

import { injectBearerToken, requestReject } from "@/api/axios";

export const signingCallbacks = [
  "SIGNER_COMPLETE",
  "PACKAGE_COMPLETE",
  "DOCUMENT_SIGNED",
] as const;

export type CallBackType = typeof signingCallbacks[number];

export interface UserCallback {
  name: CallBackType;
  packageId: string;
}

interface ValidSignSigningEndpoint {
  update_package: (data: UserCallback) => Promise<AxiosResponse<void>>;
}

interface SigningApi {
  validsign: ValidSignSigningEndpoint;
}

export const signingApi = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_VALIDSIGN_URL,
    timeout: process.env.VUE_APP_API_SHORT_REQUEST_TIMEOUT as
      | number
      | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

const api: SigningApi = {
  validsign: {
    update_package(data: UserCallback) {
      return signingApi().post(`/validsign/`, data);
    },
  },
};
export default api;
