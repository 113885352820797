import axios, { AxiosInstance } from "axios";
import { injectBearerToken, requestReject } from "@/api/axios";
import { ParticipantType } from "@/models/participant";

const portalApi = (): AxiosInstance => {
  const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: process.env.VUE_APP_API_REQUEST_TIMEOUT as number | undefined,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  });
  axiosInstance.interceptors.request.use(injectBearerToken, requestReject);
  return axiosInstance;
};

export interface ApiErrorResponse<T extends string> {
  timestamp: string;
  status: number;
  type: string;
  message: T;
}

export type UpdateErrorResponses =
  | "Can't change a signer that already or partially signed."
  | "User is not allowed to sign this document";

export const participantsApi = {
  participants: {
    put: (
      taskId: string,
      participantXID: string,
      data: { type: ParticipantType; id: string }
    ) => {
      return portalApi().put<string>(
        `/api/tasks/${taskId}/participants/${participantXID}`,
        data
      );
    },
  },
};
