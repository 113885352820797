



















import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import api, { CallBackType } from "@/api/signing";
import { Breakpoints } from "@/store/modules/layout";
import { namespace } from "vuex-class";
import { ValidSignCallbackEvent } from "@/components/modals/ValidSignTypes";
import { AxiosError } from "axios";
import { LogArgument } from "rollbar";

const layout = namespace("layout");

@Component
export default class ValidSignModal extends Vue {
  @Prop()
  readonly messageBus!: Vue;

  private packageId: string | null = null;

  private signingUrl: string | null = null;

  @layout.Getter
  public responsiveAtLeast!: (breakpoint: Breakpoints) => boolean;

  mounted() {
    this.messageBus.$on("show-signing-modal", this.showModal);
    this.messageBus.$on("hide-signing-modal", this.hideModal);
  }

  beforeDestroy() {
    this.signingUrl = null;
    this.messageBus.$off("show-signing-modal", this.showModal);
    this.messageBus.$off("hide-signing-modal", this.hideModal);
  }

  showModal(data: { url: string; packageId: string }) {
    const { packageId, url } = data;
    this.$modal.hide(this.modalName);
    this.signingUrl = url;
    this.packageId = packageId;
    this.$nextTick(() => {
      this.$modal.show(this.modalName);
    });
  }

  hideModal() {
    this.$modal.hide(this.modalName);
    this.signingUrl = null;
    this.packageId = null;
  }

  closeListeners() {
    window.removeEventListener("message", this.receiveMessage, false);
  }

  openListeners() {
    window.addEventListener("message", this.receiveMessage, false);
  }

  get modalWidth(): string {
    return this.responsiveAtLeast(Breakpoints.lg) ? "80%" : "100%";
  }

  public receiveMessage(event: any) {
    const origin = event.origin || event.originalEvent.origin;
    const data = event.data;

    switch (data) {
      case "ESL:MESSAGE:REGISTER":
        event.source.postMessage("ESL:MESSAGE:ACTIVATE_EVENTS", origin);
        break;
      case "ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE":
        this.handleCallback("ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE");
        break;
      case "ESL:MESSAGE:ERROR:DOCUMENT_CONFIRM":
        this.handleCallback("ESL:MESSAGE:ERROR:DOCUMENT_CONFIRM");
        break;
      case "ESL:MESSAGE:SUCCESS:DOCUMENT_CONFIRM":
        this.handleCallback("ESL:MESSAGE:SUCCESS:DOCUMENT_CONFIRM");
        break;
      default:
        break;
    }
  }

  private handleCallback(event: ValidSignCallbackEvent) {
    const callBackName = this.resolveFromValidSignEvent(event);
    if (callBackName === undefined) {
      this.failed(`Undefined callback ${event}`, null);
      return;
    }

    api.validsign
      .update_package({
        name: callBackName,
        packageId: this.packageId || "",
      })
      .then(() => this.completed(event))
      .catch((error: AxiosError<void>) => this.failed(event, error));
  }

  resolveFromValidSignEvent(
    status: ValidSignCallbackEvent
  ): CallBackType | undefined {
    const mapper: {
      [t in ValidSignCallbackEvent]: CallBackType | undefined;
    } = {
      "ESL:MESSAGE:SUCCESS:SIGNER_COMPLETE": "SIGNER_COMPLETE",
      "ESL:MESSAGE:SUCCESS:DOCUMENT_CONFIRM": "DOCUMENT_SIGNED",
      "ESL:MESSAGE:ERROR:DOCUMENT_CONFIRM": undefined,
    };
    return mapper[status];
  }

  @Emit()
  completed(value: string) {
    return value;
  }

  @Emit()
  failed(value: string, error: AxiosError<void> | null) {
    if (error === null) {
      this.$rollbar.error("User experienced a signing error", value);
    } else {
      this.$rollbar.error("User experienced a signing error", value, error);
    }
    return value;
  }

  get modalName() {
    return `signModal-${this.packageId}`;
  }
}
