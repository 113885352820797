

































































import { Component, Prop, Ref } from "vue-property-decorator";
import { tasksApi } from "@/api/portal/tasks";
import accessControl from "@/api/access_control";
import { Document, FlowName } from "@/models/document";
import FormInputField from "@/components/form-components/FormInputField.vue";
import FormTextAreaField from "@/components/form-components/FormTextAreaField.vue";
import djlMultiselect from "@/components/form-components/djlMultiselect.vue";
import { FormField } from "@/components/form-components/types";
import { Employee } from "@/models/employee";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { TaskCategory } from "@/models/task";

@Component({
  components: { FormTextAreaField, FormInputField, djlMultiselect },
})
@Translatable("modals")
export default class RejectDocumentModal extends TranslatableComponent {
  @Ref() readonly reasonInput!: FormField;
  @Prop() private document!: Document;
  @Prop() private componentName!: string;
  @Prop() private category!: TaskCategory;

  private reason: string = "";
  private selectedEmployee: Employee | null = null;
  private employeeList: Employee[] = [];

  private cancel() {
    this.$modal.hide(this.componentName);
  }

  mounted() {
    this.findEmployees(this.document.account.id);
  }

  private shouldNotifyEmployee() {
    return this.document.flowName !== FlowName.MFILES_SIGNING;
  }

  private findEmployees(accountId: string) {
    accessControl.accounts
      .accountEmployees(accountId, { authorizedToSign: true })
      .then(({ data }) => {
        this.employeeList = data;
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_component_value("cannot_fetch_employee_for_document")
        );
      });
  }

  private submit() {
    if (!this.reasonInput.validate()) {
      this.$snotify.error(
        this.translated_component_value("reason_for_delete_required")
      );
      return;
    }
    this.deleteDocument(
      this.reason,
      this.selectedEmployee === null ? null : this.selectedEmployee.id
    );
  }

  private deleteDocument(reason: string, recipient: string | null) {
    tasksApi
      .delete(this.document.id, reason, recipient)
      .then((data) => {
        this.$snotify.success(
          this.translated_component_value("delete_document_success")
        );
        this.$router.push({
          name: "documents",
          query: { category: this.category },
        });
      })
      .catch((data) => {
        this.$snotify.error(
          this.translated_component_value("delete_document_failed")
        );
      });
  }
}
