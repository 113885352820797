














import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { FormField } from "@/components/form-components/types";
import CheckboxField from "@/components/form-components/CheckboxField.vue";

@Component({
  components: { CheckboxField },
  inheritAttrs: false,
})
export default class FormCheckboxField extends Vue implements FormField {
  /**
   * Things like inputmode, pattern, placeholder, minlength, maxlength, required, etc.
   * Are bound 1-on-1 through v-bind="$attrs"
   */

  @Prop({ default: "" }) public label!: string;

  @Prop({ default: false, type: Boolean }) required!: boolean;

  @Prop() public checked!: boolean;

  public isValidatedOnce = false;
  protected focused: boolean = false;

  get filled() {
    const value = (this.$refs.input as HTMLFormElement).value || "";
    return this.focused || value.length > 0;
  }

  public validate() {
    this.isValidatedOnce = true;
    return true; //todo: implement proper validation
  }

  protected onInput(e: any) {
    this.isValidatedOnce = false;
    this.updateValue(e);
  }

  protected isValid() {
    return this.isValidatedOnce ? this.checkValidity() : true;
  }

  protected getValidity() {
    const input = this.getInputField();
    return input ? input.validity : {};
  }

  @Emit("input")
  private updateValue(value: any) {
    return value;
  }

  private getInputField(): HTMLInputElement | null {
    return this.$refs.input ? (this.$refs.input as HTMLInputElement) : null;
  }

  private checkValidity() {
    const input = this.getInputField();
    return input ? input.checkValidity() : false;
  }
}
