

























import { Component, Prop } from "vue-property-decorator";
import { Document } from "@/models/document";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Translatable("modals")
@Component({
  props: {
    document: Document,
  },
})
export default class AdoptionProgressSteps extends TranslatableComponent {
  @Prop() private document!: Document;
}
