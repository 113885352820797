




































import Component from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Document } from "@/models/document";
import { Emit, Prop } from "vue-property-decorator";
import DocumentLayout from "@/components/documents/DocumentLayout.vue";
import DocumentDetails from "@/components/documents/DocumentDetails.vue";
import DocumentAttachments from "@/components/documents/DocumentAttachments.vue";
import AuditlogComponent from "@/components/documents/AuditlogComponent.vue";
import { TaskCategory } from "@/models/task";
import { ActionType, DocumentAction } from "@/views/documents/DocumentActions";
import { insertIf } from "@/lib/helpers/conditional";
import { SigningLink, tasksApi } from "@/api/portal/tasks";
import SignEmployeeMessageModal from "@/components/modals/SignEmployeeMessageModal.vue";
import { isEmpty } from "lodash";
import Vue from "vue";
import ValidSignModal from "@/components/modals/ValidSignModal.vue";
import RejectDocumentModal from "@/components/modals/RejectDocumentModal.vue";
import UserAction from "@/components/documents/user_actions/UserAction.vue";
import { TaskRedirect } from "@/views/documents/task_redirects";
import { ParticipantType } from "@/enums/ParticipantType";
import { Participant } from "@/models/participant";
import TaskTakeoverModal from "@/components/modals/TaskTakeoverModal.vue";

@Component({
  methods: { isEmpty },
  components: {
    TaskTakeoverModal,
    ValidSignModal,
    AuditlogComponent,
    DocumentLayout,
    DocumentDetails,
    DocumentAttachments,
    SignEmployeeMessageModal,
    RejectDocumentModal,
    UserAction,
  },
})
@Translatable("documents.states")
export default class SentState extends TranslatableComponent {
  @Prop()
  private document!: Document;
  @Prop()
  private taskCategory!: TaskCategory;
  @Prop()
  private messageBus!: Vue;

  get userActions(): {
    value: DocumentAction;
    action: () => any;
    actionType: ActionType;
  }[] {
    if (this.document === null) {
      return [];
    }
    return [
      ...insertIf(this.$ability.can("sign", this.document, "employee"), {
        value: "sign_document",
        action: () => this.employeeSigning(this.document.id),
        actionType: "primary",
      }),
      ...insertIf(this.$ability.can("take_over", this.document), {
        value: "take_over",
        action: () => this.$modal.show("taskTakeoverModal"),
        actionType: "secondary",
      }),
      ...insertIf(this.$ability.can("revoke", this.document), {
        value: "revoke_document",
        action: () => this.$modal.show("revokeModalEmployee"),
        actionType: "secondary",
      }),
      ...insertIf(this.$ability.can("adopt", this.document), {
        value: "adopt",
        action: () => this.messageBus.$emit("show-adoption-modal"),
        actionType: "primary",
      }),
    ] as {
      value: DocumentAction;
      action: () => any;
      actionType: ActionType;
    }[];
  }

  mounted() {
    this.messageBus.$on("start-signing", this.openSigningModal);
  }

  beforeDestroy() {
    this.messageBus.$off("start-signing", this.openSigningModal);
  }

  private employeeSigning(documentId: string) {
    this.document?.participants.some(
      (participant) => participant.participantType === "CLIENT"
    )
      ? this.$modal.show(
          SignEmployeeMessageModal,
          { documentId, i18n: this.$i18n },
          { height: "auto", maxWidth: 600, adaptive: true },
          {
            "before-close": (params: { params?: boolean }) => {
              params?.params && this.openSigningModal();
            },
          }
        )
      : this.openSigningModal();
  }

  get employeeSigner(): Participant | undefined {
    return this.document?.participants.find((participant) => {
      return (
        participant.role === "signer" &&
        participant.participantType === ParticipantType.EMPLOYEE
      );
    });
  }

  public openSigningModal() {
    this.retrieveSigningLink(this.document.id)
      .then((data) => {
        this.messageBus.$emit("show-signing-modal", {
          url: data.signingLink,
          packageId: this.document.signingInfo?.packageId,
        });
      })
      .catch(() => {
        this.$snotify.error(
          this.translated_component_value("notifications.signing.error")
        );
      });
  }

  private retrieveSigningLink(documentId: string): Promise<SigningLink> {
    return new Promise((resolve, reject) => {
      tasksApi
        .signingLink(documentId)
        .then((data) => {
          resolve(data.data);
        })
        .catch((data) => {
          reject(data);
        });
    });
  }

  taskTakeOverFinished() {
    this.$modal.hide("taskTakeoverModal");
  }

  private finishSigning(message: string) {
    this.$snotify.success(
      this.translated_component_value("notifications.signing.success")
    );
    this.messageBus.$emit("hide-signing-modal");
    this.return("signing");
  }

  private failedSigning(message: string) {
    this.$snotify.error(
      this.translated_component_value("notifications.signing.failed")
    );
  }

  @Emit("return")
  private return(redirectPath: TaskRedirect) {}
}
