export enum DocumentType {
  ADVIES = "advies",
  OFFERTE = "offerte",
  OPDRACHTBEVESTIGING = "opdrachtbevestiging",
  OVEREENKOMST_OVERIG = "overeenkomst-overig",
  LOR = "lor",
  CONCEPT_JAARREKENING = "concept-jaarrekening",
  NOTULEN = "notulen",
  CONTROLEVERKLARING = "controleverklaring",
  VERKLARING_PENSIOEN_EN_STAMRECHT = "verklaring-pensioen-en-stamrecht",
  BEZWAARSCHRIFT = "bezwaarschrift",
  DIVIDENDBELASTING = "dividendbelasting",
  BRIEF = "brief",
  AKKOORD_MJL = "akkoord-mjl",
  ONDERZOEKSRAPPORT_PROGNOSE = "onderzoeksrapport-prognose",
  VENNOOTSCHAPSBELASTING = "vennootschapsbelasting",
  VERWERKERSOVEREENKOMST = "verwerkersovereenkomst",
  BEOORDELINGSVERKLARING = "beoordelingsverklaring",
  SAMENSTELLINGSVERKLARING = "samenstellingsverklaring",
  INKOMSTENBELASTING = "inkomstenbelasting",
  KREDIETRAPPORTAGE_BEPERKT = "kredietrapportage-beperkt",
  KREDIETRAPPORTAGE_MIDDELGROOT = "kredietrapportage-middelgroot",
  KREDIETRAPPORTAGE_PERSOON = "kredietrapportage-persoon",
  OMZETBELASTING = "omzetbelasting",
  OMZETBELASTING_SUPPLETIE = "omzetbelasting-suppletie",
  ICP = "icp",
  JAARREKENING_MICRO = "jaarrekening-micro",
  JAARREKENING_KLEIN = "jaarrekening-klein",
  JAARREKENING_MIDDELGROOT = "jaarrekening-middelgroot",
  JAARREKENING_GROOT = "jaarrekening-groot",
  INRICHTINGSJAARREKENING_MICRO = "inrichtingsjaarrekening-micro",
  INRICHTINGSJAARREKENING_KLEIN = "inrichtingsjaarrekening-klein",
  INRICHTINGSJAARREKENING_MIDDELGROOT = "inrichtingsjaarrekening-middelgroot",
  INRICHTINGSJAARREKENING_GROOT = "inrichtingsjaarrekening-groot",
}
