














































import { Component, Emit, Prop } from "vue-property-decorator";
import { tasksApi } from "@/api/portal/tasks";
import { Document } from "@/models/document";
import FormCheckboxField from "@/components/form-components/FormCheckboxField.vue";
import djlDatepicker from "@/components/form-components/djlDatepicker.vue";
import formatISO from "date-fns/formatISO";
import parseISO from "date-fns/parseISO";
import TranslatableComponent from "@/lib/mixins/translateable";

@Component({
  components: { djlDatepicker, FormCheckboxField },
  props: {
    document: Document,
  },
})
export default class AdoptDocument extends TranslatableComponent {
  @Prop() private document!: Document;

  private adopted: boolean = false;
  private adoptionDate: Date | null = null;
  private isProcessingSubmit: boolean = false;

  @Emit()
  private canceled(): void {}

  mounted() {
    //TODO in plaats van mounted zou ik deze graag uitgevoerd zien op het moment van laden van de dialoog, dan is deze if ook niet nodig.
    if (this.document.state === "ADOPTION_REQUIRED") {
      this.getAdoptionStatus(this.document.id);
    }
  }

  private setAdopted(adopted: boolean) {
    this.adopted = adopted;
  }

  private getAdoptionStatus(documentId: string) {
    tasksApi
      .getAdoptionStatus(documentId)
      .then(({ data }) => {
        this.adopted = data.accept;
        if (data.date !== null) {
          this.adoptionDate = parseISO(data.date);
        }
      })
      .catch((err) => {
        this.$snotify.error(
          this.translated_component_value("cannot_get_adoption_data")
        );
      });
  }

  @Emit()
  private done(): string {
    return "adopt-document";
  }

  private submit(): void {
    if (this.isProcessingSubmit) return;
    this.isProcessingSubmit = true;
    if (this.adopted && this.adoptionDate === null) {
      this.$snotify.error(
        this.translated_component_value("adoption_date_is_required")
      );
      return;
    }
    let dateString = null;
    if (this.adoptionDate != null) {
      dateString = formatISO(this.adoptionDate, { representation: "date" });
    }
    tasksApi
      .adoptDocument(
        this.document.id,
        this.adopted === null ? false : this.adopted,
        dateString
      )
      .then((response) => {
        this.$snotify.success(
          this.translated_component_value("adoption_date_is_processed")
        );
        this.done();
      })
      .catch((data) => {
        this.$snotify.error(
          this.translated_component_value("saving_adoption_date_failed")
        );
      })
      .finally(() => {
        this.isProcessingSubmit = false;
      });
  }
}
