





















import Component, { mixins } from "vue-class-component";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Prop } from "vue-property-decorator";
import { TranslatedUserActions } from "@/components/documents/user_actions/useractions";
import { isEmpty } from "lodash";
import Multiselect from "vue-multiselect";

@Translatable("documents.user_actions")
@Component({
  components: { Multiselect },
  methods: { isEmpty },
})
export default class EmployeeUserActions extends mixins(TranslatableComponent) {
  @Prop()
  private actions!: TranslatedUserActions[];

  private selected: { value: string; action: () => any } | null = null;
}
