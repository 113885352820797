















import { Component, Emit, Prop } from "vue-property-decorator";
import { tasksApi } from "@/api/portal/tasks";
import ReasonModalContent from "@/components/modals/partials/ReasonModalContent.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Component({
  components: { ReasonModalContent },
})
@Translatable("modals")
export default class SignEmployeeMessageModal extends TranslatableComponent {
  @Prop() private documentId!: string;

  @Emit("close")
  private cancel(parameters: boolean) {
    return parameters;
  }

  private submit(reason: string): void {
    tasksApi
      .putMailmessageForOwnerSigned(this.documentId, reason || "")
      .then(() => {
        this.cancel(true);
        return true;
      })
      .catch(() => {
        this.$snotify.error(
          "Er is iets mis gegaan bij het opslaan van uw mailbericht."
        );
      });
  }
}
