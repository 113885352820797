





























import TranslatableComponent from "@/lib/mixins/translateable";
import { Component, Prop } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { RepresentativeContactDetails } from "@/api/magnoliaCmsApi";

@Component
export default class ContactCardMinimalistic extends mixins(
  TranslatableComponent
) {
  @Prop()
  public representativeContactDetails?: RepresentativeContactDetails;
  private fallBackImage: string = require("@/assets/fallbackImage.jpg");

  showDefaultImage(event: Event) {
    (event!.target! as HTMLImageElement).src = this.fallBackImage;
  }

  get fullName() {
    return `${this.representativeContactDetails?.firstName} ${this.representativeContactDetails?.lastName}`;
  }

  get avatarImage() {
    return this.representativeContactDetails?.imageUrl
      ? process.env.VUE_APP_MAGNOLIA_CMS_URL +
          this.representativeContactDetails?.imageUrl
      : "";
  }
}
