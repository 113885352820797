



























import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";
import { Component, Emit, Prop } from "vue-property-decorator";
import BasicModal from "@/components/modals/partials/BasicModal.vue";
import { Participant, participantTypeFromRole } from "@/models/participant";
import {
  ApiErrorResponse,
  participantsApi,
  UpdateErrorResponses,
} from "@/api/portal/participants";
import { namespace } from "vuex-class";
import { AppRole } from "@/lib/abilities/abilities";
import { Role } from "@/models/role";
import { AxiosError } from "axios";

type DisplayError = "generic" | "cant_change_signer" | "not_authorized_to_sign";

const user = namespace("user");

@Component({
  components: {
    BasicModal,
  },
})
@Translatable("modals")
export default class TaskTakeoverModal extends TranslatableComponent {
  @Prop()
  readonly participant!: Participant;

  @Prop()
  readonly taskId!: string;

  @user.State("role")
  private role!: AppRole;

  @user.State("userId")
  private userId!: string;

  private errorMessage: DisplayError | null = null;
  private isLoading: boolean = false;

  get name(): string {
    return `${this.participant.firstName} ${this.participant.lastName}`;
  }

  @Emit("cancel")
  private actionCancel(): void {
    return;
  }

  private handleError(error: AxiosError) {
    const body = (
      error.response?.data as ApiErrorResponse<UpdateErrorResponses>
    ).message;
    const errors: Record<UpdateErrorResponses, DisplayError> = {
      "Can't change a signer that already or partially signed.":
        "cant_change_signer",
      "User is not allowed to sign this document": "not_authorized_to_sign",
    };
    this.errorMessage = errors[body] || "generic";
  }

  @Emit("success")
  private success(): void {
    return;
  }

  private handleClick() {
    this.errorMessage = null;
    this.isLoading = true;
    const participantType = participantTypeFromRole(this.role as Role);
    if (participantType == null) return;
    participantsApi.participants
      .put(this.taskId, this.participant.xid, {
        id: this.userId,
        type: participantType,
      })
      .then(() => {
        this.success();
      })
      .catch((error: AxiosError) => {
        this.handleError(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }
}
