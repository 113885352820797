










































import { Component, Emit, Prop } from "vue-property-decorator";
import { Document } from "@/models/document";
import AdoptionProgressSteps from "@/components/modals/partials/AdoptionProgressSteps.vue";
import TranslatableComponent, {
  Translatable,
} from "@/lib/mixins/translateable";

@Translatable("modals")
@Component({
  components: {
    AdoptionProgressSteps,
  },
  props: {
    document: Document,
  },
})
export default class AdoptionProgress extends TranslatableComponent {
  @Prop() private document!: Document;
  @Prop() private componentName!: string;

  @Emit()
  private canceled(): void {}
}
